<template>
  <div>
    <section class="meet_our_team">
      <div class="container">
        <div class="banner_hed text-center">
          <h3 class="heading_2 font_bold text_black mb_70">Meet Our Awesome Team</h3>
        </div>
        <div class="founders">
          <div class="row justify-content-center">
            <div class="col-xxl-7 col-md-10">
              <div class="meet_head mb_60">
                <div class="heading">Founders</div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6">
                   <!-- <div class="member_img" v-tooltip.top-start="'You have new messages.'" > -->
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="text_black">
                      <img src="../../assets/home/images/team/headshot.png" alt="" class="img-fluid">
                      <div class="member_name"> Vivienne
                        Fleischer, B.M, CEAS<br> President & Co-Founder
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#andrew" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Andrew Blumenfeld,
                        MA<br> CEO & Co-Founder</div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="founders">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="meet_head mb_60">
                <!-- Heading change -->
                <div class="heading">Ergonomists</div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#lori" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson3.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Lori Babcock, OT, CEAS<br>
                      Ergo Program Manager</div></a>
                      
                    
                  </div>
                </div>
                <!-- Title change -->
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#erin" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson9.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Erin Patterson, MPH<br>
                      Sr. Ergonomist / Program Development Specialist</div></a>
                      
                    
                  </div>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#andrewadam" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson1.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Andrew Adam, D.C., B.S.<br>
                      PBE Ergonomist</div></a>
                  </div>
                </div>
                <!-- Add New -->
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#marcibaptista" class="text_black">
                    <img src="../../assets/home/images/team/Ergonomist-Marci-Baptista-m.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Marci Baptista<br>
                      PBE Ergonomist</div></a>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#isaiah" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson4.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Isaiah Calub<br>
                      PBE Ergonomist</div></a>
                  </div>
                </div>
                <!-- Add new -->
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#mariagonzalez" class="text_black">
                    <img src="../../assets/home/images/team/Ergonomist - Maria Gonzalez-1.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Maria Gonzalez<br>
                      PBE Ergonomist</div></a>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#jeff" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson7.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Jeff Walikonis, M.S., C.P.D.M<br>
                      PBE Ergonomist</div></a>
                      
                    
                  </div>
                </div>
                <!-- Add new -->
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#leanzhang" class="text_black">
                    <img src="../../assets/home/images/team/ErgonomistLeahZhang-m.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Lean Zhang<br>
                      PBE Ergonomist</div></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="founders">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="meet_head mb_60">
                <!-- Heading change -->
                <div class="heading">Client Success Specialists</div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#ursula" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson10.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Ursula D’Angelo<br>
                      Sr. Client Support Specialist</div></a>
                      
                    
                  </div>
                </div>
                <!-- Change in name -->
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#ashleynikolaev" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson11.png" alt="" class="img-fluid">
                    <div class="member_name">
                      <!-- Ashley Nikolaev<br> -->
                      Ashley Novak <br>
                      Client Support Specialist</div></a>                    
                    
                  </div>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#jeanette" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson12.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Jeanette Walker<br>
                      Client Support Specialist</div></a>                      
                    
                  </div>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#sarahreed" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson13.png" alt="" class="img-fluid">
                    <div class="member_name">

                      Sarah Reed<br>
                      Client Support Specialist</div></a>
                      
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="founders">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="meet_head mb_60">
                <!-- Heading change -->
                <div class="heading">Tech Gurus</div>
              </div>
              <div class="row">
               <!-- Add new -->
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#chrisg" class="text_black">
                    <img src="../../assets/home/images/team/Tech-Chris-G-m.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Chris G<br>
                      IT Tech Manager</div></a>  
                  </div>
                </div>
                <!-- Add new -->
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#khloefaria" class="text_black">
                    <img src="../../assets/home/images/team/Tech - Khloe Faria-1.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Khloe Faria<br>
                      Tech Software Admin</div></a>  
                  </div>
                </div>
                <!-- Add new -->
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#maxwellwagonerwatts" class="text_black">
                    <img src="../../assets/home/images/team/Tech - Maxwell Wagoner-Watts-1.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Maxwell Wagoner-Watts<br>
                      Tech Team Lead</div></a>  
                  </div>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-3">
                  <div class="member_img" data-bs-toggle="tooltip" data-bs-placement="top" title="Click to see Bio">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#christine" class="text_black">
                    <img src="../../assets/home/images/team/erin-patterson15.png" alt="" class="img-fluid">
                    <div class="member_name">
                      Christine Lagorio<br>
                      IT Support Specialist</div></a>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Founders -->
      <!-- Change content  -->
        <div class="modal fade meet_our_team_modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/headshot.png" alt="" class="img-fluid">
                  <div class="member_name mt_20">
                    <a href="#" class="text_black">Vivienne Fleischer, B.M, CEAS<br> President & Co-Founder</a>
                  </div>
                  <div class="wfh_box my_50">
                    <p>Vivienne Fleischer, President and Co-Founder is passionate about wellness! A concert pianist turned ergonomist (but she does still play) she’s on this crazy mission to “save the hands of the world” while helping people to stave off injuries, take charge of their health and feel their best!</p>
                    <p><b>Little Known Facts:</b>  Vivienne also green juices daily, loves yoga, plays a mean piano and loves loves loves to write! Oh, she’s also a vegan, an aspiring cook and a wanna be cartoonist. She loves spending time with her amazing family.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      <!-- Change content -->
       <div class="modal fade meet_our_team_modal" id="andrew" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/erin-patterson.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Andrew Blumenfeld, MA<br> CEO & Co-Founder
                  </div>
                  <div class="wfh_box my_50">
                    <p>Andrew Blumenfeld, CEO & Co-Founder comes from a family of educators and lawyers and athletes. Helping people to overcome adversity through skills acquisition and perseverance makes me feel as if I am giving something back. Legacy is important!</p>
                    <p><b>Little Known Facts:</b>  Andrew enjoys the torture of being a Bay Area sports fan. Go Giants! Go Sharks! Working out, hitting golf balls (sometimes even straight!) and bbqing a great bone-in Rib Eye with sautéed Zucchini , red bell pepper and mushrooms and a Newcastle chaser. On the mellower side, give me some Ray Davies, Mark Knopfler, Eric Burdon or a classic movie like Cool Hand Luke and I’m perfect</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    <!-- Founders end -->
  <!-- Ergonomists -->
    <!-- Chnage content -->
    <div class="modal fade meet_our_team_modal" id="lori" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/erin-patterson3.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Lori Babcock, OT, CEAS<br>
                      Ergo Program Manager
                  </div>
                  <div class="wfh_box my_50">
                    <p>Lori loves the problem solving that goes into helping someone become more comfortable when working. Often it is simple changes that will make all the difference! I also love showing people how changing their movement patterns, not only their equipment, can make them more comfortable and more productive.</p>
                    <p><b>Little Known Facts:</b>  She lives in Sonoma County on the beautiful Russian River with her family. She enjoy kayaking in the summer and mushroom hunting in the winter. She also likes to zip line and has been skydiving! (Who knew?)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Chnage content -->
    <div class="modal fade meet_our_team_modal" id="andrewadam" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/erin-patterson1.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Andrew Adam, D.C., B.S.<br>
                      PBE Ergonomist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Andrew has worked with proper body mechanics in exercise, therapy, and ergonomics. This includes treating injuries, instructing stretches, training rehabilitation/exercises, and injury prevention to reach optimal results.As a healthcare provider, he aids patients in relieving pain by treating the root cause of most types of neuromusculoskeletal conditions. Aside from pain management, Andrew uses his skills in posture evaluation to teach proper ergonomics to prevent future pain.</p>
                    <p><b>Little Known Facts:</b>  San Jose born and raised. Outside of work, hobbies include playing pickup basketball and watching Warriors and Raiders games with friends and family.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add new modal -->
    <div class="modal fade meet_our_team_modal" id="marcibaptista" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/Ergonomist-Marci-Baptista-m.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Marci Baptista<br>
                      PBE Ergonomist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Marci was born and raised in New York. She received a Masters in Occupational Therapy (OT) from Samuel Merritt University and a Doctorate from Loma Linda University.  She is a Certified Ergonomic Assessment Specialist and a Certified Hand Therapist.  She also teaches Masters and doctorate occupational therapy students.  Marci has been performing ergonomic evaluations for 15 years and continues to treat patients in the clinic. Marci loves helping people and sharing her knowledge and education with others. </p>
                    <p><b>Little Known Facts:</b>   Before Marci became an OT, she worked at the United Nations and was a singer in a band.  She also ran 12 marathons in the New York area and completed 3 triathlons. She lives with her husband and has 2 children and a 120 lb german shepard named after the baseball player, Derek Jeter.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="modal fade meet_our_team_modal" id="isaiah" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="/img/erin-patterson4.88e138f5.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Isaiah Calub<br>
                      PBE Ergonomist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Isaiah is a helping hand in the Health and Wellness Industry. His background in coaching multiple sports & fitness training led to understanding biomechanics. As a Health Science Graduate with an emphasis in Public Health, he shares his knowledge from person to person & local communities. Isaiah uses his experiences to apply ergonomics as an everyday priority. </p>
                    <p><b>Little Known Facts:</b>  Isaiah likes to play musical instruments as well as tracking & mixing in his spare time. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <!-- Add new modal -->
    <div class="modal fade meet_our_team_modal" id="mariagonzalez" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/Ergonomist - Maria Gonzalez-1.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Maria Gonzalez<br>
                      PBE Ergonomist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Maria is a Colombian Industrial Designer with a Master's in Industrial Engineering focused on Ergonomics. She has worked on usability and occupational ergonomics in medical devices development industries, also in Ecopetrol S.A. the refinery in Colombia. She has lived in California since 2020. She loves to study and learn new skills to improve her professional development in order to help people in a better way.</p>
                    <p><b>Little Known Facts:</b> She's a very sentimental person.  She especially likes to read books that contribute to her personal growth, meditate, and connect with nature on a walk or bike ride.  Coffee is part of her daily life and if it is accompanied by a dessert or cookie, much better.  She loves animals and dreams of one day having a shelter for homeless dogs and cats.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <!-- Title change -->
    <div class="modal fade meet_our_team_modal" id="erin" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="/img/erin-patterson9.af827678.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Erin Patterson, MPH<br>
                     Sr. Ergonomist / Program Development Specialist
                  </div>  
                  <div class="wfh_box my_50">
                    <p>Erin has a master’s degree in public health and has been a practicing ergonomist for over 20 years. Erin has a passion for helping people and her absolute favorite thing about her job  is when she makes a tiny adjustment for someone and they immediately say, “Wow, I can’t believe it. That feels better already.” </p>
                    <p><b>Little Known Facts:</b>  When Erin is not working, she enjoys spending time with her family and friends exploring new restaurants, museums, or other fun spots around New York City where she lives with her husband and two teenagers. She also loves cooking and baking (but mostly the eating part), long road trips where she gets to be the driver (and therefore, the DJ), and recently has taken up birding (any excuse to find a bit of nature in New York City).</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <!-- Chnage content -->
    <div class="modal fade meet_our_team_modal" id="jeff" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/erin-patterson7.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Jeff Walikonis, M.S., C.P.D.M<br>
                      PBE Ergonomist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Jeff has had the privilege of assisting nearly 30,000 workers with their ergonomic needs over the past 15 years, all while spending the past 30 years assisting veterans and injured workers as a voc rehab counselor. His passion is helping people improve their habits and workstations through ergonomics to help prevent them from needing his assistance as a voc rehab counselor.</p>
                    <p><b>Little Known Facts:</b>  Jeff grew up on a huge wheat farm in Montana. When he was 7, his 10 year old brother taught him how to drive a grain truck by himself. Rhubarb pie is his favorite dessert and he enjoys spending time with his wonderful wife and two great kids!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- Add new modal -->
    <div class="modal fade meet_our_team_modal" id="leanzhang" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/ErgonomistLeahZhang-m.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Lean Zhang<br>
                      PBE Ergonomist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Leah Zhang is an Ergonomics Specialist and Certified Alexander Technique Teacher. Leah carries over 16 years of experience in the physical therapy and wellness field. She has helped many clientsmake meaningful adjustments to their work setup and postural habits in order to release chronic pain. Leah is also a nationally certified teacher of the Alexander Technique, with over 1600 hours of hands-on raining. The Alexander Technique is a thoughtful and effective way to improve posture and reduce chronic back, shoulder and neck pain. Leah has been a featured presenter at the National Ergo Expo in Las Vegas regarding her work in posture and ergonomics.</p>
                    <p><b>Little Known Facts:</b>  Leah performs in an improv and sketch troupe and is very funny</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!--Ergonomists End -->
  
  <!-- Client Success Specialists -->
  <!-- Change content-->
    <div class="modal fade meet_our_team_modal" id="ursula" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/erin-patterson10.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Ursula D’Angelo<br>
                      Sr. Client Support Specialist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Ursula hails from the East Coast with a background in architecture & landscape design.  She brings nearly two decades of experience from the project & client management realms and prides herself on providing exceptional service & coordination.  </p>
                    <p><b>Little Known Facts:</b>  Ursula likes to garden & to travel when she can. She likes to build sand-castles with her kids (although really hates getting the sand off of them later!) & enjoys binge-reading, baking & working on projects around the house.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Change in name -->
    <div class="modal fade meet_our_team_modal" id="ashleynikolaev" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/erin-patterson11.png" alt="" class="img-fluid">
                  <div class="member_name">
                    <!-- Ashley Nikolaev<br> -->
                    Ashley Novak <br>
                      Client Support Specialist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Ashley comes from an administrative background in behavioral health and truly enjoys helping others. When she has free time, she loves to spend time outdoors with her dogs and traveling to picture-worthy hiking destinations.</p>
                    <p><b>Little Known Facts:</b>   Ashley is an avid baker and loves to create decorative sugar cookies for every holiday imaginable. She also loves to spoil her dogs with homemade puppy friendly treats!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="modal fade meet_our_team_modal" id="jeanette" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/erin-patterson12.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Jeanette Walker<br>
                      Client Support Specialist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Jeanette Walker has over two decades of Account Management and Product Development experience. Throughout her career she has been passionate about helping others succeed and being their very best.  Two things she strongly believes - that building relationships with clients and co-workers is extremely important to success and that any setback is an opportunity to come back stronger.</p>
                    <p><b>Little Known Facts:</b>  Jeanette is a big Twilight Zone fan and can watch the episodes over and over again. She loves to root on the local sports teams, particularly the Giants, Niners & Warriors. Spending time with her family and friends makes her happy and lastly, she is writing her first book 😊</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <!-- Chnage content-->
    <div class="modal fade meet_our_team_modal" id="sarahreed" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img">
                  <img src="../../assets/home/images/team/erin-patterson13.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Sarah Reed<br>
                      Client Support Specialist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Sarah has been working in the administrative/clerical field for over ten years. She has been called a “Gal Friday” since she does a little of everything!</p>
                    <p><b>Little Known Facts:</b>  If she’s not attending an adoption event with her foster dogs you will find her gardening. She aspires to one day have a beautiful cut flower garden so she never needs to buy a flower bouquet again. She also loves growing her own vegetables to eat.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Client Success Specialists End -->

  <!-- Tech Gurus -->
    <!-- Add new modal -->
    <div class="modal fade meet_our_team_modal" id="chrisg" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img" >
                  <img src="../../assets/home/images/team/Tech-Chris-G-m.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Chris G<br>
                    IT Tech Manager
                  </div>
                  <div class="wfh_box my_50">
                    <p>Chris has a Bachelors in computer science from Penn State and has been working in the field for almost 20 years. He has an extensive background in information security and is passionate about building efficient, secure systems.</p>
                    <p><b>Little Known Facts:</b>  Chris enjoys being in nature and away from the hustle and bustle of the city.  He spends much of his time in the mountains camping and fishing.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add new modal -->
    <div class="modal fade meet_our_team_modal" id="khloefaria" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img" >
                  <img src="../../assets/home/images/team/Tech - Khloe Faria-1.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Khloe Faria<br>
                    Tech Software Admin
                  </div>
                  <div class="wfh_box my_50">
                    <p>Khloe is a developer and IT specialist with a passion for learning and problem-solving. Since elementary school, she has had an interest in technology and studied programming as well as computer engineering, and uses her knowledge to assist the team every day.</p>
                    <p><b>Little Known Facts:</b>  Khloe is also a hobbyist voice actress, and sometimes provides voice over for projects online. When she's not at work, she likes to spend time with her friends watching shows and playing video games (her favorite is the Persona series!) She also loves coffee, cooking, painting, and yoga.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add new modal -->
    <div class="modal fade meet_our_team_modal" id="maxwellwagonerwatts" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img" >
                  <img src="../../assets/home/images/team/Tech - Maxwell Wagoner-Watts-1.png" alt="" class="img-fluid">
                  <div class="member_name">
                    Maxwell Wagoner-Watts<br>
                    Tech Team Lead
                  </div>
                  <div class="wfh_box my_50">
                    <p>Maxwell is an experienced advisor, liaison, and leader bridging the gap in technical communication. Driven by passion, he takes pride in providing the best service possible. As a leader his goals include gaining PMP and CMP certification. In addition to work, Maxwell spends time advising and volunteering with organizations.</p>
                    <p><b>Little Known Facts:</b>  Maxwell won a lifetime supply of "Starburst" candy in the 1990s by guessing the mystery flavor. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade meet_our_team_modal" id="christine" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="member_img" >
                  <img src="../../assets/home/images/team/erin-patterson15.png" alt="" class="img-fluid">
                  <div class="member_name">
                   Christine Lagorio<br>
                      IT Support Specialist
                  </div>
                  <div class="wfh_box my_50">
                    <p>Christine has a background in administration and organizational/program coordination, Christine has helped diverse companies and not-for-profits in providing tech and administrative support. She understands how employee safety, health and wellness can positively impact any organization, and stands behind the goal of supporting employees as best as possible.</p>
                    <p><b>Little Known Facts:</b>  Christine enjoys CrossFit and exercising in her spare time. She has a collection of magnets from wherever she has traveled to, and also loves sushi and sake!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Tech Gurus End -->
  </div>
</template>

<script>
  // import { mapGetters } from "vuex";
  import store from "@/store/index";
  export default {
    name: "meet_the_team",
    mounted() {
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      store.dispatch("Home/getHomeData").then(() => {});
    },
  };
</script>